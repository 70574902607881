<div class="container">
    <br><br><br><br>
    <section>
        <div class="container">
          <br>
            <div class="row">
                <div class="col-xs-12 col-sm-2 col-md-2"></div>
                <div class="col-xs-12 col-sm-4 col-md-4">
                    <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
                        <div class="mainflip">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <div class="fa fa-file" style="font-size:4em;"></div>
                                        <h4 class="card-title"><fa-icon [icon]="faNetworkWired"></fa-icon></h4>
                                        <p class="card-text">Provedores</p>
                                          <!-- <button  class="btn btn-primary">Entrar</button> -->
                                          <button  class="btn btn-primary" (click)="vaiProviders()">Entrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4">
                    <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
                        <div class="mainflip">
                            <div class="frontside">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <div class="fa fa-file" style="font-size:4em;"></div>
                                        <h4 class="card-title"><fa-icon [icon]="faFilm"></fa-icon></h4>
                                        <p class="card-text">VOD</p>
                                          <button  class="btn btn-primary" (click)="vaiVod()">Entrar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-2 col-md-2"></div>
            </div>
        </div>
    </section>
    <br><br><br><br>
  </div>