import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ConfirmModel {
  title: string;
  message: string;
}
@Component({
  selector: 'app-providermodal',
  templateUrl: './providermodal.component.html',
  styleUrls: ['./providermodal.component.css']
})
export class ProvidermodalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string;
  message: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }


}
