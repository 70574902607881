import { Component, OnInit } from '@angular/core';
import { UsersService } from  '../../users-services/users.service';
import { ProvidersService } from  '../../providers-service/providers.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { DatamodalComponent } from '../datamodal/datamodal.component'
import { DeletemodalComponent } from '../deletemodal/deletemodal.component'
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  
  faEdit = faEdit;
  faTrash = faTrash;
  faUser = faUser;

  userResp: UserData;
  Users: any[] = [];

  constructor(private spinner: NgxSpinnerService, private usersService: UsersService, private providersService: ProvidersService, private router: Router, private SimpleModalService: SimpleModalService) { }
  
  deleteUser(email){
    localStorage.setItem('DELETE_USER', email);
    this.SimpleModalService.addModal(DeletemodalComponent, {
      title: "",
      message: ""
    }).subscribe((response: any) => {
      this.Users = []
      this.ngOnInit();
    });
    
  }

  createUser(){
    this.router.navigateByUrl('/admin/create-user');
  }

  showModal(title, data) {
    if(title == "Providers"){
      let sendData = {
        providers: data
      }
      this.providersService.getProviders(sendData)
      .subscribe((response: any) => {
        let pNames = []
        response.data.forEach(el => {
          pNames.push("<span class='dataName'>"+el.name+"</span>")
        });
        this.SimpleModalService.addModal(DatamodalComponent, {
          title: title,
          message: pNames.join("")
        })
      },
      error=> {
        alert("Sua sessão expirou, faça login novamente!");
        this.router.navigateByUrl('/login');
      });
    }else{
      let pNames = []
      data.forEach(el => {
        pNames.push("<span class='dataName'>"+el+"</span>")
      });
      this.SimpleModalService.addModal(DatamodalComponent, {
        title: title,
        message: pNames.join("")
      })
    }



  }

  edit(email, name, origins, type, channels){
    localStorage.setItem('USER_EDIT_EMAIL', email);
    localStorage.setItem('USER_EDIT_NAME', name);
    localStorage.setItem('USER_EDIT_ORIGINS', origins);
    localStorage.setItem('USER_EDIT_TYPE', type);
    localStorage.setItem('USER_EDIT_CHANNELS', channels);
    this.router.navigateByUrl('/admin/edit-user');
  }


  clearStorage(){
    localStorage.setItem('USER_EDIT_EMAIL', "");
    localStorage.setItem('USER_EDIT_NAME', "");
    localStorage.setItem('USER_EDIT_ORIGINS', "");
    localStorage.setItem('USER_EDIT_TYPE', "");
    localStorage.setItem('USER_EDIT_CHANNELS', "");
    localStorage.setItem('DELETE_USER', "");
  }


  ngOnInit(): void {
  this.spinner.show();
  this.clearStorage()
    this.usersService.getUsers()
      .subscribe((response: any) => {
        this.spinner.hide();
        response.users.forEach(element => {
          this.userResp = element;
          this.Users.push(this.userResp)
        });
      },
      error=> {
        this.spinner.hide();
        alert("Sua sessão expirou, faça login novamente!");
        this.router.navigateByUrl('/login');
      });

  }

}

export class UserData {
  name: string;
  email: string;
  password: string;
  type: string;
  origins: string;
}
