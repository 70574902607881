<div class="container">
  <table class="table">
      <thead>
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">Email</th>
          <th scope="col">Tipo</th>
          <th scope="col">Providers / Canais</th>
          <th scope="col"><button class="btn btn-success" (click)="createUser()"><fa-icon [icon]="faUser"></fa-icon> Adicionar usuário</button></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of Users">
          <td>{{user.name}}</td>
          <td>{{user.email}}</td>
          <td>{{user.type}}</td>
          <td *ngIf="user.type == 'admin'" (change)="show == !show" ></td>
          <td *ngIf="user.type == 'programadora'" (change)="show == !show" ><button class="btn btn-primary" (click)="showModal('Channels', user.channels)">Ver channels</button></td>
          <td *ngIf="user.type == 'provedor'" (change)="show == !show" ><button class="btn btn-primary" (click)="showModal('Providers',user.origins)">Ver providers</button></td>
          <td><a role="button" (click)="edit(user.email, user.name, user.origins, user.type, user.channels)"><fa-icon [icon]="faEdit"></fa-icon> editar</a></td>
          <td><a role="button" (click)="deleteUser(user.email)"><fa-icon [icon]="faTrash"></fa-icon> deletar</a></td>
        </tr>
      </tbody>
    </table>
    
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > Carregando... </p></ngx-spinner>