<div class="container">
    <br>
    <div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->
  
      <!-- Icon -->
      <div class="fadeIn first">
        <br><br>
        <a class="navbar-brand logo" href="/"><img width="70" src="/assets/imgs/logo.png" alt="Filipe Jacobus"></a>
        <br><br>
      </div>
  
      <!-- Login Form -->
      <form [formGroup]="loginForm" class="login-container" (ngSubmit)="login()">
        <p [ngClass]="{ 'has-error': isSubmitted && formControls.email.errors }">
          <input type="email" placeholder="Email" formControlName="email">
        </p>


        <div *ngIf="isSubmitted && formControls.email.errors" class="help-block">
          <div *ngIf="formControls.email.errors.required">Email is required</div>
        </div> 
  
        <p [ngClass]="{ 'has-error': isSubmitted && formControls.password.errors }">
          <input type="password" placeholder="Senha" formControlName="password">
        </p>
 
        <div *ngIf="isSubmitted && formControls.password.errors" class="help-block">
          <div *ngIf="formControls.password.errors.required">Password is required</div>
        </div> 
  
        <p>
          <input type="submit" class="btn btn-primary-blog" value="Log in">
        </p>
      </form>
  
      </div>
    </div>
    <br /><br /><br />
  </div>