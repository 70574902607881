import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-datamodal',
  templateUrl: './datamodal.component.html',
  styleUrls: ['./datamodal.component.css']
})
export class DatamodalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string;
  message: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
