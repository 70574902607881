import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router } from  '@angular/router';
// import { Title } from '@angular/platform-browser';

import { UsersService } from  '../users-services/users.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private usersService: UsersService, private router: Router, private formBuilder: FormBuilder ) { }

  loginForm: FormGroup;
  isSubmitted  =  false;

  clearStorage(){
    localStorage.setItem('USER_EMAIL', "");
    localStorage.setItem('USER_NAME', "");
    localStorage.setItem('USER_ORIGINS', "");
    localStorage.setItem('USER_TYPE', "");
    localStorage.setItem('USER_CHANNELS', "");
    localStorage.setItem('ACCESS_TOKEN', "");
  }

  ngOnInit() {
    this.clearStorage();
    this.loginForm  =  this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get formControls() { return this.loginForm.controls; }
  login(){
    this.isSubmitted = true;
    if(this.loginForm.invalid){
      return;
    }
    this.usersService.login(this.loginForm.value).subscribe((response: any) => {

      localStorage.setItem('ACCESS_TOKEN', response.token);
      localStorage.setItem('USER_NAME', response.user.name);
      localStorage.setItem('USER_TYPE', response.user.type);
      localStorage.setItem('USER_ORIGINS', response.user.origins);
      localStorage.setItem('USER_CHANNELS', response.user.channels);
      localStorage.setItem('USER_EMAIL', response.user.email);
      
      if (response.user.type == "admin"){
        this.router.navigateByUrl('/admin');
      }else if(response.user.type == "programadora"){
        this.router.navigateByUrl('/programadora');
      }else{
        this.router.navigateByUrl('/provedor/home');
      }

    },
    error=> {
      alert("Usuário ou senha incorretos!")
    });

  }
}
