<div class="container">
    <br>
    <h2>Gerenciar provedores</h2>
    <hr />
    <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-4"></div>
        <div class="col-xs-12 col-sm-4 col-md-4">

            <form [formGroup]="postForm" *ngIf="showInput" (ngSubmit)="salvar()">
                <div class="form-row">
                    <div id="origins-container" class="form-group col-md-12">
                        <label for="providers">Excluir provedores da visão</label>
                       
                        <ng-select name="providers" formControlName="origins" type="text" class="form-control" id="origins" [items]="allProviders"
                            [addTag]="addTagFn"
                            [hideSelected]="true"
                            multiple="true"
                            bindLabel="name"
                            [(ngModel)]="selectedCompanies">
                        </ng-select>
                    </div>
               
                    

                </div>
      
                <button type="submit" class="btn btn-primary">Salvar</button>&#160;&#160;
                <button type="button" (click)="voltar()" class="btn btn-secondary">Voltar</button>
            </form>


        </div>
        <div class="col-xs-12 col-sm-4 col-md-4"></div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > Carregando... </p></ngx-spinner>