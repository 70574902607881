import { Component, OnInit } from '@angular/core';
import { ProvidersService } from  '../providers-service/providers.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ProvidermodalComponent } from '../admin/providermodal/providermodal.component'
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-provedor',
  templateUrl: './provedor.component.html',
  styleUrls: ['./provedor.component.css']
})
export class ProvedorComponent implements OnInit {

  pData = []
  dtArr = []
  pIndex = 0
  s2Total = 0
  private loadComponent = false;

  optionsParent;
  optionsPkg;
  optionsChannel;

  constructor(private spinner: NgxSpinnerService, private providersService: ProvidersService, private SimpleModalService: SimpleModalService, private router: Router) { }
  
  getMaxTotal(data, n, i, mObj){
    let max = 0;
    let filtered;
    let maxm;
    data.forEach(item => {
      if (item.total > max) {
        max = item.total;
        maxm = item
      }
    });
    mObj.push(maxm)
    filtered = data.filter(function(el) { return el.name != maxm.name ; }); 
  
    if(i < n-1){
      i++
      this.getMaxTotal(filtered, n, i, mObj)
    }
    return mObj
    
  }
  sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  mountChart(id, data){
    let grafName = "Assinantes por pacote"
    let grafSub = 'Top assinantantes por pacote'
    if (id == "SUMÁRIO"){
      grafName = "Assinantes por provedor"
      grafSub = 'Top assinantantes por provedor'
    } 
    this.sleep(500).then(() => {
      let collapse = document.getElementsByClassName('show')
           
      let namesArr = []
      let dataArr = []
      let max = this.getMaxTotal(data, 8, 0, [])

      max.forEach(el => {
        namesArr.push(el.name)
        dataArr.push({"name":el.name, "value":el.total})
      });

      this.optionsParent = {
        title: {
          text: 'Assinantes por canal',
          subtext: 'Top 8 de assinantantes por canal',
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          x: 'center',
          y: 'bottom',
          data: namesArr
        },
        calculable: true,
        series: [
          {
            name: 'area',
            type: 'pie',
            radius: [30, 110],
            roseType: 'area',
            data: dataArr
          }
        ]
      };

      let pkg = []
      data.forEach(el => {
        el.values.forEach(elem => {
          for(var ind in elem){
            if(pkg[ind] == undefined){
              pkg[ind] = []
            }
            pkg[ind].push(parseInt(elem[ind], 10)) 
          }
        });
      });


      
      let namesArrPkg = []
      let dataArrPkg = []
      
    for(var ind in pkg){
        let obj = {"name": ind, "value": Math.max.apply(null, pkg[ind])}
        dataArrPkg.push(obj)
        namesArrPkg.push(ind)
    }

      this.optionsPkg = {
        title: {
          text: grafName,
          subtext: grafSub,
          x: 'center'
        },
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: namesArrPkg,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {  
            type: 'bar',
            barWidth: '60%',
            data: dataArrPkg
          }
        ]
      };



      let chn = []
      data.forEach(el => {
        el.values.forEach(elem => {
          for(var ind in elem){
            if(chn[ind] == undefined){
              chn[ind] = 0
            }
            chn[ind]= 1 + chn[ind]
          }
        });
      });

      let namesArrChn = []
      let dataArrChn = []
      
    for(var ind in chn){
        let obj = {"name": ind, "value": chn[ind]}
        dataArrChn.push(obj)
        namesArrChn.push(ind)
    }
    if (id != "SUMÁRIO"){
      this.optionsChannel = {
        title: {
          text: 'Canais por pacote',
          subtext: 'Top canais por pacote',
          x: 'center'
        },
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: namesArrChn,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {  
            type: 'bar',
            barWidth: '60%',
            data: dataArrChn
          }
        ]
      };
    }else{
      this.optionsChannel = null
    }
      if (collapse.length < 1){
        this.loadComponent = false;
      }else{
        this.loadComponent = false;
        this.sleep(500).then(() => {
          this.loadComponent = true;
        })
        
      }
         
  
   });
  }

  toArray(answers: object) {
    return Object.keys(answers).map(key => answers[key])
  }

  formatId(id){
    id = id.split("'").join('');
    id = id.split('.').join('');
    id = id.split(' ').join('');
    id = id.split('!').join('');
    id = id.split('?').join('');
    id = id.split('#').join('');
    if(/\d/.test(id.charAt(0))){
      id = "a"+id
    }
    return id
  }

  redClassMethod(id){
    let ret = ""
    let rc = ""
    this.pData.forEach(itr =>{

      if(itr.data.length == 0 ){
        if(id == itr.name){
          ret = "redClass";
          rc = "redClassOn"

          let formId = this.formatId(id)
          let elemId = document.getElementById(formId)
          if(elemId != undefined){
            elemId.remove();
          }
          
        }
      }

    })

    return ret + " " + rc;
  }

  openProvModal(values){
    let temp = "";
    values.forEach(element => {
      for(var it in element){
        temp = temp + "<span class=''><strong>"+it+"</strong>: "+element[it]+" usuários<span><br>"
      }
    });

    this.SimpleModalService.addModal(ProvidermodalComponent, {
      title: "Dados dos pacotes",
      message: temp
    })
  }

  ngOnInit(): void {
    this.spinner.show();
    this.pIndex = 0
    let origns = localStorage.getItem('USER_ORIGINS')
 
    let orArr = origns.split(',')

    let sendData = {
      "providers": orArr
    }
    this.providersService.getProviders(sendData)
    .subscribe((response: any) => {
      this.spinner.hide();
      this.s2Total = response.providers
      response.data.forEach(el => {
        this.pIndex++

        let dt = JSON.parse(el.data)
        
        let nData = []

        if(dt.data.result != undefined){
          dt.data.result.forEach(elm => {
           let pkg = elm.metric.pkg
           let val = 0
           
           if(elm.value != undefined){
    
              
              val = elm.value[1]
             
             
           }

           let obj = []
           obj[pkg] = val
           if(nData[elm.metric.chname] != undefined){
            nData[elm.metric.chname].push(obj)
           }else{
            nData[elm.metric.chname] = []
            nData[elm.metric.chname].push(obj)
           }
            
          });
        }
        let fData = []
        for (var key in nData){
        
          let total = 0
         nData[key].forEach(item => {
                    
            for(var ind in item){
              total = total + parseInt(item[ind],10)
            }
            
         });
          
        

          let nObj ={
            "name":key,
            "values":nData[key],
            "total":total
          }
          fData.push(nObj)
        }

        el.data =  fData
        

        this.pData.push(el)

      });
      let totalChan = []
      let dataChans = []
      
    

      this.pData.forEach(val=>{
 
        val.data.forEach(elm => {
          if(totalChan[elm.name] == undefined){
            totalChan[elm.name] = 0
          }
          totalChan[elm.name] = totalChan[elm.name] + elm.total
    
        });
      
      })
      
      for(var ind in totalChan){
        
        let dataPkg = []
        
        this.pData.forEach(val=>{
          let dpkg = []
          val.data.forEach(elm => {
            if(elm.name == ind){
              
              let cnt = 0;
              for(var idx of elm.values){
                for(var indx in idx){
                  cnt = parseInt(idx[indx], 10) + cnt
                }
                
              }
              dpkg[val["name"]] = cnt.toString();

              // for(var idx in elm.values[0]){
              //   if(dpkg[idx] == undefined){
              //     dpkg[idx] = "0" 
              //   }
              //   let dp = parseInt(dpkg[idx], 10)  + parseInt(elm.values[0][idx], 10)
              //   dpkg[idx] = dp.toString();
              // }
            }
          })
          dataPkg.push(dpkg)
        })


        let obj = {"name":ind, "values":dataPkg, "total": totalChan[ind]}
        dataChans.push(obj)
      }
      this.pData.unshift({name: "SUMÁRIO", origin: "0.0.0.0", data: dataChans})
      this.mountChart('SUMÁRIO', dataChans)
      
      
    },
    error=> {
      this.spinner.hide();
      alert("Sua sessão expirou, faça login novamente!");
      this.router.navigateByUrl('/login');
    });

  }


}
