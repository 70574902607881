<div class="container">
    <section>
        <br>
        <h1>Provedores ({{pIndex}} de {{s2Total}})</h1>
        <hr>
        <div class="row">
            <!-- acordion -->
            <div class="col-xs-12 col-sm-12 col-md-12">
                <div id="accordion">
                    <div *ngFor="let data of pData" class="card">
                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link {{redClassMethod(data.name)}}" data-toggle="collapse" [attr.data-target]="'#' + formatId(data.name)" (click)="mountChart(data.name, data.data)" aria-expanded="true" aria-controls="collapseOne">
                            <span class="title-ac">
                                <strong>{{data.name}}<span class="redClassOff"> &nbsp;(Não foi possível obter os dados)</span></strong>
                            </span>
                          </button>
                        </h5>
                      </div>
                  
                      <div is-open="teste" id="{{formatId(data.name)}}" class="collapse {{data.name == 'SUMÁRIO' ? 'show': ''}}"aria-labelledby="headingOne" data-parent="#accordion">
                       <div class="container">
                            <div class="row">
                                <!-- dados -->
                                <div class="col-xs-12 col-sm-7 col-md-7">
                                    
                                  <div class="continer">


                                    <table class="table table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">Nome do canal</th>
                                          <th scope="col">Total de usuários</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let d of toArray(data?.data)">
                                          <td><a style="color:#007bff;" role="button" (click)="openProvModal(d.values)">{{d.name}}</a></td>
                                          <td>{{d.total}}</td>
                                        </tr>
                                      </tbody>
                                    </table>

                                  </div>
                                                     
                                  
                                </div>
                                <!-- graficos -->
                                <div class="col-xs-12 col-sm-5 col-md-5">
                                  <br>
                                  <div id="{{formatId(data.name)}}_chart">
                                    <span *ngIf= '!loadComponent'>Carregando gráficos...</span>
                                    <app-charts [optionsChn]=optionsChannel [options]=optionsParent [optionsPkg]=optionsPkg  *ngIf= 'loadComponent'></app-charts>
                                  </div>
                                  
                                </div>
                            </div>
                       </div>

                      </div>
                    </div>
                  </div>
            </div>
            
        </div>
    </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > Carregando... </p></ngx-spinner>