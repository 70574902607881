<div class="modal-content">
    <div class="modal-header">
      <h4>{{title || 'Confirmar'}}</h4>
    </div>
    <div class="modal-body">
      <p>{{message || 'Tem certeza que quer deletar este usuário?'}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="cancel()">Cancel</button>
      <button type="button" class="btn btn-danger" (click)="confirm()">OK</button>
    </div>
  </div>