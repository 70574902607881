<div class="container">
    <br>
    <h2>Criar usuário</h2>
    <hr />
    <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-4"></div>
        <div class="col-xs-12 col-sm-4 col-md-4">

            <form [formGroup]="postForm" *ngIf="showForm" (ngSubmit)="salvar()">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label for="tipo">Tipo</label>
                        <select (change)="changeType()" name="tipo" id="alterarTipo" class="form-control">
                          <option id="admin">admin</option>
                          <option id="programadora">programadora</option>
                          <option id="provedor">provedor</option>
                        </select>
                    </div>
                    <div class="form-group col-md-12">
                        <label for="nome">Nome</label>
                        <input name="nome" formControlName="name" type="text" class="form-control" id="name" placeholder="Nome" >
                    </div>
                    <div class="form-group col-md-12">
                        <label for="email">Email</label>
                        <input name="email" formControlName="email" type="text" class="form-control" id="email" placeholder="email" >
                    </div>
                    <div class="form-group col-md-12">
                        <label for="senha">Senha</label>
                        <input  name="senha" formControlName="password" type="password" class="form-control" id="password" placeholder="Senha">
                    </div>
                    <div id="origins-container" class="form-group col-md-12">
                        <label for="providers">Provedores</label>
                       
                        <ng-select (change)="verifySelect()" name="providers" formControlName="origins" type="text" class="form-control" id="origins" [items]="allProviders"
                            [addTag]="addTagFn"
                            [hideSelected]="true"
                            multiple="true"
                            bindLabel="name"
                            [(ngModel)]="selectedCompanies">
                        </ng-select>
                    </div>
                    <div id="channels-container" class="form-group col-md-12">

                        <label for="channels">Channels</label>
                        <ng-select  name="channels" formControlName="channels" type="text" class="form-control" id="channels" [items]="channelsList"
                        [hideSelected]="true"
                        multiple="true"
                        bindLabel="name"
                        [(ngModel)]="channelsselected">
                    </ng-select>
                      

                        <!-- <input name="channels" formControlName="channels" type="text" class="form-control" id="channels" placeholder="channels" value={{chanelSt}}> -->
                    </div>
                    

                </div>
      
                <button type="submit" class="btn btn-primary">Salvar</button>&#160;&#160;
                <button type="button" (click)="voltar()" class="btn btn-secondary">Voltar</button>
            </form>


        </div>
        <div class="col-xs-12 col-sm-4 col-md-4"></div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > Carregando... </p></ngx-spinner>