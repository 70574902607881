import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {
  
  faUsers = faUsers;
  faNetworkWired = faNetworkWired;
  faCogs = faCogs;
  
  constructor( private router: Router) { }

  vaiLista(){
    this.router.navigateByUrl('/admin/users');
  }
  vaiProviders(){
    this.router.navigateByUrl('/admin/providers-home');
  }
  vaiManage(){
    this.router.navigateByUrl('/admin/manage-providers');
  }

  ngOnInit(): void {
  }

}
