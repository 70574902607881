import { Component, OnInit } from '@angular/core';
import { ProvidersService } from  '../../providers-service/providers.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ProvidermodalComponent } from '../providermodal/providermodal.component'
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-admin-vod',
  templateUrl: './admin-vod.component.html',
  styleUrls: ['./admin-vod.component.css']
})
export class AdminVodComponent implements OnInit {

  pData = []
  dtArr = []
  fData = []
  pIndex = 0
  back =false
  sumary;
  s2Total = 0
  tIndex = 10
  lastSum = 0
  searchData = "";
  private loadComponent = false;

  optionsParent;
  optionsPkg;
  optionsChannel;

  constructor(private spinner: NgxSpinnerService, private providersService: ProvidersService, private SimpleModalService: SimpleModalService, private router: Router) { }
  
  getMaxTotal(data, n, i, mObj){
    let max = 0;
    let filtered;
    let maxm;

    data.forEach(item => {
   
      if (item.values > max) {
        max = item.values;
        maxm = item
        
      }
    });
    mObj.push(maxm)

    filtered = data.filter(function(el) { 
      
      return el.name != maxm.name ; 
    }); 
  
    if(i < n-1){
      i++
      this.getMaxTotal(filtered, n, i, mObj)
    }
    return mObj
    
  }
  sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  mountChart(id, data){

    this.sleep(500).then(() => {
      let collapse = document.getElementsByClassName('show')
           
      let namesArr = []
      let dataArr = []
  
      let max = this.getMaxTotal(data, 8, 0, [])
      
      max.forEach(el => {
        if (el != null){
          
          namesArr.push(el.name)
          dataArr.push({"name":el.name, "value":el.values})
        }
 
      });

      this.optionsParent = {
        title: {
          text: 'Visualizações de VODs',
          subtext: 'Top 8 VODs',
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          x: 'center',
          y: 'bottom',
          data: namesArr
        },
        calculable: true,
        series: [
          {
            name: 'area',
            type: 'pie',
            radius: [30, 110],
            roseType: 'area',
            data: dataArr
          }
        ]
      };

   


   

      if (collapse.length < 1){
        this.loadComponent = false;
      }else{
        this.loadComponent = false;
        this.sleep(500).then(() => {
          this.loadComponent = true;
        })
        
      }
         
  
   });
  }

  toArray(answers: object) {
    return Object.keys(answers).map(key => answers[key])
  }

  formatId(id){
    id = id.split("'").join('');
    id = id.split('.').join('');
    id = id.split(' ').join('');
    id = id.split('!').join('');
    id = id.split('?').join('');
    id = id.split('#').join('');
    if(/\d/.test(id.charAt(0))){
      id = "a"+id
    }
    return id
  }

  // redClassMethod(id){
  //   let ret = ""
  //   let rc = ""
  //   this.pData.forEach(itr =>{

  //     if(itr.data.length == 0 ){
  //       if(id == itr.name){
  //         ret = "redClass";
  //         rc = "redClassOn"

  //         let formId = this.formatId(id)
  //         let elemId = document.getElementById(formId)
  //         if(elemId != undefined){
  //           elemId.remove();
  //         }
          
  //       }
  //     }

  //   })

  //   return ret + " " + rc;
  // }

  // openProvModal(values){
  //   let temp = "";
  //   values.forEach(element => {
  //     for(var it in element){
  //       temp = temp + "<span class=''><strong>"+it+"</strong>: "+element[it]+" usuários<span><br>"
  //     }
  //   });

  //   this.SimpleModalService.addModal(ProvidermodalComponent, {
  //     title: "Dados dos pacotes",
  //     message: temp
  //   })
  // }


  previousPage(){
    
    document.getElementById('nextButton').classList.remove('disabled')
    let tmpNDataIndx =  this.pIndex - 10
    
    if(tmpNDataIndx <= 0){
      document.getElementById('previousButton').classList.add('disabled')
    }else{
      document.getElementById('previousButton').classList.remove('disabled')
    }
    document.getElementById('tableContent').innerHTML = ''
    this.prev()
  }

  nextPage(){
    document.getElementById('previousButton').classList.remove('disabled')
    // let tmpNDataIndx =  this.pIndex + 10
    
    if(this.tIndex+10  > this.s2Total){
      document.getElementById('nextButton').classList.add('disabled')
    }else{
      document.getElementById('nextButton').classList.remove('disabled')
    }
    document.getElementById('tableContent').innerHTML = ''
    this.next()
    // if(tmpNDataIndx < this.pData.length){
    //   this.pIndex =  this.pIndex + 10
    //   this.dataInit = this.dataInit +  10
    //   document.getElementById('tableContent').innerHTML = ''
    // }
  }
    search(value){
      if (value != ""){
        document.getElementById('nextButton').classList.add('disabled')
        document.getElementById('previousButton').classList.add('disabled')
        this.pData = []
      
        this.fData.forEach(element => {
          let name = element.name.toLowerCase();
          if(name.includes(value.toLowerCase())){
            this.pData.push(element)
          }
        });
      }else{
        document.getElementById('nextButton').classList.remove('disabled')
        this.pData = []
        this.fData = []
        this.ngOnInit()
      }


    }
  
  ngOnInit(): void {
    this.spinner.show();
    this.pIndex = 0
    let origns = []
 
    this.providersService.getAllProviders()
    .subscribe((response: any) => {
      
      response.data.forEach((dt) => {
        origns.push( dt.origin);
      }); 
      
     

    let orArr = origns

    let sendData = {
      "providers": orArr
    }
    //mock
    //  sendData = {
    //   "providers": ["172.16.30.132"]
    // }
    //mock
    this.providersService.getVods(sendData)
    .subscribe((response: any) => {
      
      this.spinner.hide();
      
      let nData = []
      this.s2Total = response.data.total
      response.data.voddata.forEach(el => {
        
        
       
        let dt = el
        
        

        
      
        
     
          let nObj ={
            "name":el.name,
            "origin":el.origin,
            "edges":el.edges,
            "values":el.data,
            "date":el.date
          }
 
        // }

        nData.push(nObj)
        let ndataArr =[]
        ndataArr.push(nObj)
        this.fData.push({name: el.name, origin: el.origin, data: ndataArr})
  
      });
      if (response.data.total > 10){
        for (let i = 0; i < 10; i++){
          if (this.fData[i+(this.pIndex-1)] != null){
            this.pData.push(this.fData[i+(this.pIndex-1)])
          }
         
        }
        this.sumary = {name: "SUMÁRIO", origin: "0.0.0.0", data: nData}
        this.pData.unshift(this.sumary)
        this.fData.unshift(this.sumary)
      }else{
        document.getElementById('nextButton').classList.add('disabled')
        this.sumary = {name: "SUMÁRIO", origin: "0.0.0.0", data: nData}
        this.fData.unshift(this.sumary)
        this.pData = this.fData
        this.tIndex = this.s2Total
      }

      this.pIndex =0
    },
    error=> {
      this.spinner.hide();
      alert("Sua sessão expirou, faça login novamente!");
      this.router.navigateByUrl('/login');
    });

        
    },
    error=> {
      this.spinner.hide();
      alert("Sua sessão expirou, faça login novamente!");
      this.router.navigateByUrl('/login');
    });
  

  }

  next(){
    
    this.pIndex +=10
    this.tIndex  +=10
    if(this.tIndex >= this.s2Total){
      this.tIndex = this.s2Total
    }
   
    this.pData = this.fData.slice(this.pIndex , this.pIndex + 10)
    
  }

  prev(){
    this.tIndex = this.pIndex
    this.pIndex -= 10
 
    this.pData = this.fData.slice(this.pIndex, this.pIndex+10)

    
  }

  ngAfterContentInit(){
    this.sleep(1000).then(() => {
      this.mountChart("SUMARIO", this.fData[0].data)
    })
   
  }
}
