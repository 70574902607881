import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { ProgramadoraComponent } from './programadora/programadora.component';
import { ProvedorHomeComponent } from './provedor-home/provedor-home.component';
import { ProvedorComponent } from './provedor/provedor.component';
import { ListComponent } from './admin/list/list.component';
import { EditComponent } from './admin/edit/edit.component';
import { CreateUserComponent } from './admin/create-user/create-user.component';
import { ProvidersComponent } from './admin/providers/providers.component';
import { AuthGuardService } from './auth-guard.service';
import { AuthGuardServiceAdmin } from './auth-guard.service';
import { AuthGuardServiceProgramadora } from './auth-guard.service';
import { AuthGuardServiceProvider } from './auth-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ManageComponent } from './admin/manage/manage.component';
import { VodComponent } from './vod/vod.component';
import { AdminProviderHomeComponent } from './admin/admin-provider-home/admin-provider-home.component';
import { AdminVodComponent } from './admin/admin-vod/admin-vod.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: 'programadora', component: ProgramadoraComponent, canActivate: [AuthGuardServiceProgramadora] },
  { path: 'provedor/home', component: ProvedorHomeComponent, canActivate: [AuthGuardServiceProvider] },
  { path: 'provedor', component: ProvedorComponent, canActivate: [AuthGuardServiceProvider] },
  { path: 'vod', component: VodComponent, canActivate: [AuthGuardServiceProvider] },
  { path: 'admin/users', component: ListComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: 'admin/edit-user', component: EditComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: 'admin/create-user', component: CreateUserComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: 'admin/providers', component: ProvidersComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: 'admin/manage-providers', component: ManageComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: 'admin/providers-home', component: AdminProviderHomeComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: 'admin/vod', component: AdminVodComponent, canActivate: [AuthGuardServiceAdmin] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
