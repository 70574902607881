import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {  HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule }         from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { ProgramadoraComponent } from './programadora/programadora.component';
import { ProvedorComponent } from './provedor/provedor.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListComponent } from './admin/list/list.component';
import { EditComponent } from './admin/edit/edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SimpleModalModule } from 'ngx-simple-modal';
import { DatamodalComponent } from './admin/datamodal/datamodal.component';
import { DeletemodalComponent } from './admin/deletemodal/deletemodal.component';
import { CreateUserComponent } from './admin/create-user/create-user.component';
import { ProvidersComponent } from './admin/providers/providers.component';
import { ProvidermodalComponent } from './admin/providermodal/providermodal.component';
import { DlChartModule, DlLegendModule, DlPieChartModule } from "dl-chart";
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { ChartsComponent } from './charts/charts.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ManageComponent } from './admin/manage/manage.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProvedorHomeComponent } from './provedor-home/provedor-home.component';
import { VodComponent } from './vod/vod.component';
import { AdminProviderHomeComponent } from './admin/admin-provider-home/admin-provider-home.component';
import { AdminVodComponent } from './admin/admin-vod/admin-vod.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    ProgramadoraComponent,
    ProvedorComponent,
    ListComponent,
    EditComponent,
    DatamodalComponent,
    DeletemodalComponent,
    CreateUserComponent,
    ProvidersComponent,
    ProvidermodalComponent,
    ChartsComponent,
    PageNotFoundComponent,
    ManageComponent,
    ProvedorHomeComponent,
    VodComponent,
    AdminProviderHomeComponent,
    AdminVodComponent
  ],
  imports: [
    DlPieChartModule,
    DlLegendModule,
    DlChartModule,
    SimpleModalModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    NgxSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
