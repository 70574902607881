import { Component, OnInit } from '@angular/core';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
@Component({
  selector: 'app-provedor-home',
  templateUrl: './provedor-home.component.html',
  styleUrls: ['./provedor-home.component.css']
})
export class ProvedorHomeComponent implements OnInit {
  faUsers = faUsers;
  faNetworkWired = faNetworkWired;
  faFilm = faFilm;
  constructor(private router: Router) { }
  vaiLista(){
    this.router.navigateByUrl('/admin/users');
  }
  vaiProviders(){
    this.router.navigateByUrl('/provedor');
  }
  vaiVod(){
    this.router.navigateByUrl('/vod');
  }

  ngOnInit(): void {
  }

}
