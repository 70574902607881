import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit {
  @Input() options;
  @Input() optionsPkg;
  @Input() optionsChn;
  
  constructor() { }
  theme: string;
  
  ngOnInit(): void {
  }

}
