import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../constants';
import { User } from '../user';

@Injectable({
  providedIn: 'root'
})

export class UsersService {

  url : string;

  constructor(private httpClient: HttpClient) {
      this.url = AppConstants.baseURL;
   }

   public deleteUser(){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let email = localStorage.getItem('DELETE_USER');
    let data = {
      "email":email
    }
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.post(this.url+"/delete-user", data, {headers: headers})
  }
  
   public getUsers(){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.get(this.url+"/get-users", {headers: headers})
  }

  public createUser(data){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.post(this.url+"/create-user", data, {headers: headers})
  }


  public editUser(data){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.post(this.url+"/edit-user", data, {headers: headers})
  }

  public login(userInfo: User){
    let headers = new HttpHeaders().set('content-type', 'application/json'); 
    return this.httpClient.post(this.url+"/login", JSON.stringify(userInfo), {headers: headers})
  }


  public isLoggedIn(){
    let ret = false
    if(localStorage.getItem('ACCESS_TOKEN') !== ""){
      ret = true
    }
    return ret;
  }

  public logout(){
    localStorage.removeItem('ACCESS_TOKEN');
  }
}