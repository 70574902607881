<div class="container">
    <section>
      <div style="margin-bottom: -15px;" class="row">
        <div class="col-xs-12 col-sm-6 col-md-6">
          <h1>Provedores ({{pIndex}})</h1>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6">
          <nav class="pagination" aria-label="Page navigation example right">
            <ul class="pagination">
              <li id="previousButton" class="page-item disabled"><a class="page-link" role="button" (click)="previousPage()" >Anterior</a></li>
              <li id="nextButton"  class="page-item"><a class="page-link" role="button" (click)="nextPage()">Próxima</a></li>
            </ul>
            <form class="form-inline">
              <div class="form-group mx-sm-3 mb-2 formBusca">
                <input type="text" class="form-control" (keyup)="search($event.target.value)" placeholder="&#xF002; Busca" style="font-family: Arial, 'Font Awesome 5 Free'" />
              </div>
              
            </form>
          </nav>
        </div>
     
     
    
    </div>
    <hr>
        <div class="row">
            <!-- acordion -->
            <div class="col-xs-12 col-sm-12 col-md-12">
                <div id="accordion">
                  <div id='tableContent' *ngFor="let data of pData, let i = index" >

                    

                    
                    <div *ngIf="i < dataIndx && i >= dataInit" class="card">


                      <div class="card-header">
                        <h5 class="mb-0">
                          <button class="btn btn-link {{aquaClassMethod(data.name)}}" data-toggle="collapse" [attr.data-target]="'#' + formatId(data.name)" (click)="mountChart(pData,data.name, data)" aria-expanded="true" aria-controls="collapseOne">
                            <span class="title-ac">
                              <strong>{{data.name}}</strong>
                          </span>
                          </button>
                        </h5>
                      </div>
                  
                      <div id="{{formatId(data.name)}}" class="collapse {{data.name == 'SUMÁRIO' ? 'show': ''}}" aria-labelledby="headingOne" data-parent="#accordion">
                       <div class="container">
                            <div class="row">
                                <!-- dados -->
                                <div class="col-xs-12 col-sm-7 col-md-7">
                                    
                                  <div class="continer">


                                    <table class="table table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">Nome do pacote</th>
                                          <th scope="col">Total de usuários</th>
                                          <th scope="col">Audiência total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td><a style="color:#007bff;" role="button" (click)="openProvModal(pData, data.name)">{{data.pkg}}</a></td>
                                          <td>{{data.users}}</td>
                                          <td>{{data.audience}}</td>
                                        </tr>
                                        
                                      </tbody>
                                    </table>

                                  </div>
                                                     
                                  
                                </div>
                                <!-- graficos -->
                                <div class="col-xs-12 col-sm-5 col-md-5">
                                  <br>
                                  <div id="{{formatId(data.name)}}_chart">
                                    <span *ngIf= '!loadComponent'>Carregando gráficos...</span>
                                    <app-charts [optionsChn]=optionsChannel [options]=optionsParent [optionsPkg]=optionsPkg  *ngIf= 'loadComponent'></app-charts>
                                  </div>
                                  
                                </div>
                            </div>
                       </div>

                      </div>
                    </div>
                      
                    </div>
                  </div>
            </div>
            
        </div>
    </section>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "small" color = "#fff" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white; text-align: center;" > Coletando dados...<br>Isso pode demorar um pouco </p></ngx-spinner>