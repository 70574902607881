import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  url : string;

  constructor(private httpClient: HttpClient) {
      this.url = AppConstants.baseURL;
   }

  public getAllProviders(){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.get(this.url+"/get-all-providers", {headers: headers})
  }
  public getAllProvidersCreateEdit(){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.get(this.url+"/get-all-providers-create-edit", {headers: headers})
  }
  public setExcluded(data){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.post(this.url+"/set-excluded", data, {headers: headers})
  }
  public getAllExcludedProviders(){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.get(this.url+"/get-excluded", {headers: headers})
  }
  public getProviders(data){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.post(this.url+"/get-providers-by-origins", data, {headers: headers})
  }

  public getVods(data){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.post(this.url+"/get-vod-audience", data, {headers: headers})
  }

  public getPackages(){
    let headers = new HttpHeaders()
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.get("http://172.18.2.11:8088/v2/web/api/services/packages", {headers: headers})
  }
  public getClients(){
    let headers = new HttpHeaders()
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.get("http://172.18.2.11:8088/v2/web/api/services/clients", {headers: headers})
  }
  public getProgramadora(data){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.post(this.url+"/get-programadora-data", data, {headers: headers})
  }
  public getChannels(){
    let token = localStorage.getItem('ACCESS_TOKEN');
    let headers = new HttpHeaders().set('token', token); // create header object
    headers = headers.append('content-type', 'application/json');
    return this.httpClient.get(this.url+"/get-channels", {headers: headers})
  }
}
