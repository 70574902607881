import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { UsersService } from  '../../users-services/users.service';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-deletemodal',
  templateUrl: './deletemodal.component.html',
  styleUrls: ['./deletemodal.component.css']
})
export class DeletemodalComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {

  title: string;
  message: string;
  constructor(private usersService: UsersService) {
    super();
  }
  confirm() {
    // on click on confirm button we set dialog result as true,
    // ten we can get dialog result from caller code
    this.usersService.deleteUser()
    .subscribe((response: any) => {
      alert("Usuário deletado com sucesso!")
    });
    this.close();
  }
  cancel() {
    this.result = false;
    this.close();
  }

}
