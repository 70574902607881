import { Component, OnInit } from '@angular/core';
import { ProvidersService } from  '../providers-service/providers.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ProvidermodalComponent } from '../admin/providermodal/providermodal.component'
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-programadora',
  templateUrl: './programadora.component.html',
  styleUrls: ['./programadora.component.css']
})
export class ProgramadoraComponent implements OnInit {

  
  pData = []
  dtArr = []
  dataIndx = 10
  dataInit = 0
  pIndex = 0
  searchData = "";
  private loadComponent = false;
  testProviders = ["VARZEA NET", "One Center", "SEANET TELECOM NAVEGANTES", "GIGALINK", "Provedor Conect","Paranhanhanet", "IZI TELECOM", " MAIS TV", " G6 TELECOM", "Goofiber", "CEDNET PROVEDOR"]
  optionsParent;
  optionsPkg;
  optionsChannel;

  constructor(private spinner: NgxSpinnerService, private providersService: ProvidersService, private SimpleModalService: SimpleModalService, private router: Router) { }
  
  search(value){
    this.searchData = value
    this.ngOnInit()    
  }


  checkDataName(name){
    name = name.toLowerCase()
    if(name.includes(this.searchData.toLowerCase()) || this.searchData == ""){
      return true
    }
    return false
  }
  previousPage(){
    document.getElementById('nextButton').classList.remove('disabled')
    this.dataIndx =  this.dataIndx - 10
    this.dataInit = this.dataInit -  10
    if(this.dataInit >= 0 && this.dataIndx > 0){
      document.getElementById('tableContent').innerHTML = ''
      if(this.dataInit == 0 && this.dataIndx == 10){
        document.getElementById('previousButton').classList.add('disabled')
        this.pData = []
        this.dtArr = []
        this.ngOnInit()
        this.dataIndx =  10
        this.dataInit = 0
      }else{
        document.getElementById('previousButton').classList.remove('disabled')
      }
    }

  }

  nextPage(){
    document.getElementById('previousButton').classList.remove('disabled')
    let tmpNDataIndx =  this.dataIndx + 1
    if(tmpNDataIndx > this.pData.length){
      document.getElementById('nextButton').classList.add('disabled')
    }else{
      document.getElementById('nextButton').classList.remove('disabled')
    }

    if(tmpNDataIndx < this.pData.length){
      this.dataIndx =  this.dataIndx + 10
      this.dataInit = this.dataInit +  10
      document.getElementById('tableContent').innerHTML = ''
    }
    

  }

  getMaxTotal(data, n, i, mObj){
    let max = 0;
    let filtered;
    let maxm;
    let el;
    data.forEach(item => {
      let usrs = item.users
      // if(usrs >= item.limit){
      //   usrs = item.limit
      // }
      if (usrs > max) {
        max = usrs;
        maxm = item
        el = item.name
      }
    });
    mObj.push(maxm)
    filtered = this.remove(data, maxm)
  
    if(i < n-1){
      i++
      this.getMaxTotal(filtered, n, i, mObj)
    }
    return mObj
    
  }

  remove(arrOriginal, elementToRemove){
    return arrOriginal.filter(function(el){return el !== elementToRemove});
}
  sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  mountChart(pdata, id, data){
    let grafName = "Assinantes por pacote"
    let grafSub = 'Top assinantantes por pacote'
    if (id == "SUMÁRIO"){
      grafName = "Assinantes por provedor"
      grafSub = 'Top assinantantes por provedor'
    } 
    this.sleep(500).then(() => {
      let collapse = document.getElementsByClassName('show')
    let len = 0;
    if(pdata != undefined)  {
      len = pdata.length;
    }


    if (id == "SUMÁRIO"){

      
    

      let provedores = []
      let users = []
      let dataArr = []

      pdata.forEach(e => {

        if(e.name != "SUMÁRIO"){
          provedores.push(e.name)
          let usrs = e.users
          // if(usrs >= e.limit){
          //   usrs = e.limit
          // } 
          users.push(usrs)
        }

      });


         /* 
      let provpiename = []
      let sorted = pdata.sort(function (a, b) {
          return b.limit - a.limit;
        });
        dataArr = []
     sorted.forEach(e => {
          provpiename.push(e.name)
          let usrs = e.users
          if(usrs >= e.limit){
            usrs = e.limit
          } 
          users.push(usrs)
          dataArr.push({"name":e.name, "value":usrs})
        });
      


      this.optionsParent = {
        title: {
          text: 'Assinantes por provedor',
          subtext: 'Top 8 de assinantantes por provedor',
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          x: 'center',
          y: 'bottom',
          data: provpiename.slice(1,9)
        },
        calculable: true,
        series: [
          {
            name: 'area',
            type: 'pie',
            radius: [30, 110],
            roseType: 'area',
            data: dataArr.slice(1,9)
          }
        ]
      };
*/
    this.optionsChannel = {
      title: {
        text: 'Usuários por provedor',
        subtext: 'Top usuários por provedor',
        x: 'center'
      },
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: provedores,
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {  
          type: 'bar',
          barWidth: '60%',
          data: users
        }
      ]
    };
  }else{
    this.optionsChannel = null

    let pacotes = []


    pacotes.push(data.pkg)
    let usrs2 = data.users
    // if(usrs2 >= data.limit){
    //   usrs2 = data.limit
    // } 
   

  this.optionsChannel = {
    title: {
      text: 'Usuários por pacote',
      subtext: 'Top usuários por pacote',
      x: 'center'
    },
    color: ['#3398DB'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: pacotes,
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {  
        type: 'bar',
        barWidth: '60%',
        data: usrs2
      }
    ]
  };

  }
    if (collapse.length < 1){
      this.loadComponent = false;
    }else{
      this.loadComponent = false;
      this.sleep(500).then(() => {
        this.loadComponent = true;
      })
      
    }
  })
  }

  toArray(answers: object) {
    return Object.keys(answers).map(key => answers[key])
  }

  formatId(id){
    id = id.split("'").join('');
    id = id.split('.').join('');
    id = id.split(' ').join('');
    id = id.split('!').join('');
    id = id.split('?').join('');
    id = id.split('#').join('');
    if(/\d/.test(id.charAt(0))){
      id = "a"+id
    }
    return id
  }

  // redClassMethod(id){
  //   let ret = ""
  //   let rc = ""
  //   this.pData.forEach(itr =>{

  //     if(itr.data.length == 0 ){
  //       if(id == itr.name){
  //         ret = "redClass";
  //         rc = "redClassOn"

  //         let formId = this.formatId(id)
  //         let elemId = document.getElementById(formId)
  //         if(elemId != undefined){
  //           elemId.remove();
  //         }
          
  //       }
        
  //     }

  //   })

  //   return ret + " " + rc;
  // }

  aquaClassMethod(id){
    let ret = ""
    let rc = ""

    if(this.testProviders.includes(id)){
      ret = "aquaClass";
      rc = "aquaClassOn"
      
    }

    return ret + " " + rc;
  }
  

  openProvModal(data, name){

    console.log(name)
    if(name == "SUMÁRIO"){

    

    let temp = ``
    data.forEach(e => {

      if(e.name != "SUMÁRIO"){
        let users = e.users
        // if (e.users >= e.limit) {
        //   users = e.limit
        // }
        temp += `<span>&nbsp;&nbsp;&nbsp;&nbsp;Provedor</span>: `+e.name+`<br>`+`<span>&nbsp;&nbsp;&nbsp;&nbsp;Usuários</span>: `+users+`<br><span>&nbsp;&nbsp;&nbsp;&nbsp;Pacote</span>: `+e.pkg+`<br><span>&nbsp;&nbsp;&nbsp;&nbsp;Audiência</span>: `+e.audience+`<br><span>&nbsp;&nbsp;&nbsp;&nbsp;CNPJ</span>: `+e.cnpj+`<br><br>`
      
      }
 
    
    });


    this.SimpleModalService.addModal(ProvidermodalComponent, {
      title: "Dados dos pacotes",
      message: temp
    })
  }
  }


  ngOnInit(): void {
    this.spinner.show();
    let udChannels = localStorage.getItem('USER_CHANNELS')
    let chArr = udChannels.split(',')
    let data = {chans: chArr}
    this.providersService.getProgramadora(data)
    .subscribe((response: any) => {
      console.log(response)
      this.spinner.hide();
      this.pData = response.data

     
      //sumário
      let getPkgs = []
      let userstotal = 0 
      let audiencetotal = 0
      response.data.forEach(el => {
         //pega os pacotes
        getPkgs.push(el.pkg)
        //soma os usuarios

        let users = el.users
        let audience = el.audience
        // if (el.users >= el.limit) {
        //   users = el.limit
        // }

        userstotal += users
        audiencetotal += audience
      });
      let uniquepkg = this.uniq(getPkgs)

      let sumary = {name: "SUMÁRIO", pkg: uniquepkg, users: userstotal, audience: audiencetotal}
      this.pData.unshift(sumary)

      this.pIndex =  this.pData.length - 1
    }); 
  }

  uniq(a) {
    return a.sort().filter(function(item, pos, ary) {
        return !pos || item != ary[pos - 1];
    });
}

}
