import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";

import { UsersService } from  '../../users-services/users.service';
import { ProvidersService } from  '../../providers-service/providers.service';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {

 
  postForm: FormGroup;
  selectedCompanies;

  allProviders: any[] = [];
  providers: any[] = [];
  
  chanelSt;

  showInput = false;

  constructor(private spinner: NgxSpinnerService, private usersService: UsersService, private providersService: ProvidersService, private router: Router, private formBuilder: FormBuilder) { }
  addTagFn(name) {
    return { name: name, tag: true };
  }
  userData: UserData = {};

  voltar(){
    this.router.navigateByUrl('/admin');
  }

  salvar(){
    if(this.postForm.value.origins == ""){
      this.postForm.value.origins = [];
    }else{
      let ids = [];
      this.selectedCompanies.forEach(el => {
        ids.push(el.id)
      });
      this.postForm.value.origins = ids;
    }
    let data = {
      "data": this.postForm.value.origins
    }
    this.providersService.setExcluded(data)
      .subscribe((response: any) => {
          alert("Configurações salvas com sucesso!");
      },
      error=> {
        alert("Ocorreu um erro ao editar o usuário!");
    });
  }
  

  ngOnInit() {
    this.spinner.show();

    this.postForm  =  this.formBuilder.group({
      origins: ['', Validators.required]
    });
    
    this.providersService.getAllProviders()
    .subscribe((response: any) => {
      
      response.data.forEach((dt) => {
        this.allProviders.push({ id: dt.origin, name: dt.name });
      }); 
      
        this.providersService.getAllExcludedProviders()
        .subscribe((response: any) => {
          this.spinner.hide();
          if(response.data != null){
            response.data.forEach((dt) => {
              this.providers.push({ id: dt.origin, name: dt.name });
              this.showInput = true;
            },
            error=> {
              alert("Sua sessão expirou, faça login novamente!");
              this.router.navigateByUrl('/login');
            }); 
            this.selectedCompanies = this.providers
          }

        });
        
    },
    error=> {
      this.spinner.hide();
      alert("Sua sessão expirou, faça login novamente!");
      this.router.navigateByUrl('/login');
    });
  }
}
interface UserData {
  name ? : string;
  email ? : string;
  password ? : string;
  type ? : string;
  origins ? : string;
}