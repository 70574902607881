import { Component, OnInit } from '@angular/core';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { faFilm } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-provider-home',
  templateUrl: './admin-provider-home.component.html',
  styleUrls: ['./admin-provider-home.component.css']
})
export class AdminProviderHomeComponent implements OnInit {

  faUsers = faUsers;
  faNetworkWired = faNetworkWired;
  faFilm = faFilm;
  constructor(private router: Router) { }
  vaiLista(){
    this.router.navigateByUrl('/admin/users');
  }
  vaiProviders(){
    this.router.navigateByUrl('/admin/providers');
  }
  vaiVod(){
    this.router.navigateByUrl('/admin/vod');
  }

  ngOnInit(): void {
  }

}
