import { Component } from '@angular/core';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  faSignOutAlt = faSignOutAlt;
  title = 'portal';
  isLogin = false;
  adminMenu = false;

  constructor(location: Location, private router: Router){
    router.events.subscribe(val => {
      
      if (location.path() != "/login") {
        this.isLogin = true
        let utp = localStorage.getItem('USER_TYPE')

        if(utp == "admin"){
          this.adminMenu = true
        }else{
          this.adminMenu = false
        }
      }else{
        this.isLogin = false
        this.adminMenu = false
      }
    });
  }
  
  logout(){
    localStorage.setItem('ACCESS_TOKEN', "");
    this.router.navigateByUrl('/login');
  }

 

}
