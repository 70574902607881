import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";

import { UsersService } from  '../../users-services/users.service';
import { ProvidersService } from  '../../providers-service/providers.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})

export class EditComponent implements OnInit {

  showForm = false;
  showProv = false;
  showProg = false;

  admSel = false;
  progSel = false;
  provSel = false;
  channelsselected;
  postForm: FormGroup;
  selectedCompanies;

  allProviders: any[] = [];
  providers: any[] = [];
  
  chanelSt;
  channelsList;

  constructor(private spinner: NgxSpinnerService, private usersService: UsersService, private providersService: ProvidersService, private router: Router, private formBuilder: FormBuilder) { }
  addTagFn(name) {
    return { name: name, tag: true };
  }
  userData: UserData = {};

  voltar(){
    this.router.navigateByUrl('/admin/users');
  }

  changeType(){
    let chType = (<HTMLInputElement>document.getElementById("alterarTipo"));
    let oc = document.getElementById("origins-container");
    let cc = document.getElementById("channels-container");
    if (chType.value == "provedor" ){
      // oc.style.display = "block"
      this.showProv = true
    }else{
      // oc.style.display = "none"
      this.showProv = false
    }
    if (chType.value == "programadora" ){
      // cc.style.display = "block"
      this.showProg = true
    }else{
      // cc.style.display = "none"
      this.showProg = false
    }
  }

  salvar(){
    let trinCn = []
    if(this.channelsselected != undefined){
      this.channelsselected.forEach(el => {
        trinCn.push(el.gid)
      });
    }

    this.postForm.value.channels = trinCn
    
    let tp = (<HTMLInputElement>document.getElementById("alterarTipo"));
    let udEmail = localStorage.getItem('USER_EDIT_EMAIL')
    this.postForm.value.email = udEmail;
    this.postForm.value.type = tp.value;
    if(this.postForm.value.channels == ""){
      this.postForm.value.channels = [];
    }
    if(this.postForm.value.origins == ""){
      this.postForm.value.origins = [];
    }else{
      let ids = [];
      this.selectedCompanies.forEach(el => {
        ids.push(el.id)
      });
      this.postForm.value.origins = ids;
    }
    this.usersService.editUser(this.postForm.value)
      .subscribe((response: any) => {
       
          alert("Usuário editado com sucesso!");
          this.router.navigateByUrl('/admin/users');
      },
      error=> {
        alert("Ocorreu um erro ao editar o usuário!");
    });
  }
  
  verifySelect(){
    this.selectedCompanies.forEach(el => {
      if(el.name == "Selecionar todos"){
        let whitoutSa = [];
        this.allProviders.forEach((dt)=>{
          if(dt.id != 1){
            whitoutSa.push(dt)
          }
        })
        this.selectedCompanies = whitoutSa
      }
    });
  }

  ngOnInit() {
    this.spinner.show();

    this.postForm  =  this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      password: ['', Validators.required],
      origins: ['', Validators.required],
      channels: ['', Validators.required]
    });

    this.chanelSt = localStorage.getItem('USER_EDIT_CHANNELS')
    let udChannels = localStorage.getItem('USER_EDIT_CHANNELS')
    udChannels = udChannels.split(',').join(';');

    this.providersService.getAllProvidersCreateEdit()
    .subscribe((response: any) => {
      this.spinner.hide();
      this.allProviders.push({ id: 1, name: "Selecionar todos"});
      response.data.forEach((dt) => {
        this.allProviders.push({ id: dt.origin, name: dt.name });
      }); 
      let udName = localStorage.getItem('USER_EDIT_NAME')
      let udType = localStorage.getItem('USER_EDIT_TYPE')
      
      let udOrigins = localStorage.getItem('USER_EDIT_ORIGINS')

      this.providersService.getChannels()
      .subscribe((response: any) => {
        this.channelsList = response
      });

        this.postForm  =  this.formBuilder.group({
          name: [udName, Validators.required],
          type: [udType, Validators.required],
          password: ['', Validators.required],
          origins: ['', Validators.required],
          channels: [udChannels, Validators.required]
        });
        this.showForm = true; 

        if(udType == 'admin'){
          this.admSel = true;
        }
        if(udType == 'programadora'){
          this.progSel = true;
          this.showProg = true
        }
        if(udType == 'provedor'){
          this.provSel = true;
          this.showProv = true
        }
                

        let prov = udOrigins.split(",")

        this.allProviders.forEach((dt) => {
          prov.forEach((c) => {
            if(dt.id == c){
              this.providers.push({ id: dt.id, name: dt.name });
            }
          });
        
        });
  
        this.selectedCompanies = this.providers
    },
    error=> {
      this.spinner.hide();
      alert("Sua sessão expirou, faça login novamente!");
      this.router.navigateByUrl('/login');
    });
  }
}
interface UserData {
  name ? : string;
  email ? : string;
  password ? : string;
  type ? : string;
  origins ? : string;
}

