import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from  './users-services/users.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private usersService: UsersService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.usersService.isLoggedIn()){
        this.router.navigate(['login']);
        return false
      }
    return true
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthGuardServiceAdmin {

  constructor(private usersService: UsersService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.usersService.isLoggedIn()){
        this.router.navigate(['login']);
        return false
      }
      var ls = localStorage.getItem('USER_TYPE')
      console.log(ls)
      if(ls != "admin"){
        this.router.navigate(['login']);
        return false
      }
    return true
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardServiceProvider {

  constructor(private usersService: UsersService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.usersService.isLoggedIn()){
        this.router.navigate(['login']);
        return false
      }
      var ls = localStorage.getItem('USER_TYPE')
      console.log(ls)
      if(ls != "provedor"){
        this.router.navigate(['login']);
        return false
      }
    return true
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardServiceProgramadora {

  constructor(private usersService: UsersService, private router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!this.usersService.isLoggedIn()){
        this.router.navigate(['login']);
        return false
      }
      var ls = localStorage.getItem('USER_TYPE')
      console.log(ls)
      if(ls != "programadora"){
        this.router.navigate(['login']);
        return false
      }
    return true
  }
}