<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="/"><img src="/assets/imgs/logo.png" alt="cdnTv" /></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div *ngIf="adminMenu" (change)="show == !show" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a class="nav-link" routerLink="/admin">home <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/admin/users">usuários <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/admin/providers">provedores <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" routerLink="/admin/manage-providers">gerenciar provedores <span class="sr-only">(current)</span></a>
      </li>
    </ul>

  </div>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto"></ul>
    <a *ngIf="isLogin" (change)="show == !show" class="nav-link" role="button" (click)="logout()"><fa-icon [icon]="faSignOutAlt"></fa-icon> logout<span class="sr-only">(current)</span></a>
  </div>

</nav>

<router-outlet></router-outlet>

<footer class="footer mt-auto py-3">
  <div class="container footer-content">
    <span class="text-muted"><a href="https://twitter.com/filipecjacobus"><i class="fa fa-twitter-square fa-1x"></i></a> CdnTV&reg; 2020 todos os direitos reservados.</span>
  </div>
</footer>