import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerService } from "ngx-spinner";

import { UsersService } from  '../../users-services/users.service';
import { ProvidersService } from  '../../providers-service/providers.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  postForm: FormGroup;
  selectedCompanies;
  channelsselected;

  allProviders: any[] = [];
  providers: any[] = [];
  
  chanelSt;
  channelsList;

  showForm = false;

  constructor(private spinner: NgxSpinnerService, private usersService: UsersService, private providersService: ProvidersService, private router: Router, private formBuilder: FormBuilder) { }
  addTagFn(name) {
    return { name: name, tag: true };
  }
  userData: UserData = {};

  voltar(){
    this.router.navigateByUrl('/admin/users');
  }

  changeType(){
    let chType = (<HTMLInputElement>document.getElementById("alterarTipo"));
    let oc = document.getElementById("origins-container");
    let cc = document.getElementById("channels-container");
    if (chType.value == "provedor" ){
      oc.style.display = "block"
    }else{
      oc.style.display = "none"
    }
    if (chType.value == "programadora" ){
      cc.style.display = "block"
    }else{
      cc.style.display = "none"
    }
  }

  salvar(){

    let trinCn = []
    if(this.channelsselected != undefined){
      this.channelsselected.forEach(el => {
        trinCn.push(el.gid)
      });
    }
    this.postForm.value.channels = trinCn
    let tp = (<HTMLInputElement>document.getElementById("alterarTipo"));
    this.postForm.value.type = tp.value;
    if(this.postForm.value.channels == ""){
      this.postForm.value.channels = [];
    }
    if(this.postForm.value.origins == ""){
      this.postForm.value.origins = [];
    }else{
      let ids = [];
      if(this.selectedCompanies != undefined){
        this.selectedCompanies.forEach(el => {
          ids.push(el.id)
        });
      }
 
      this.postForm.value.origins = ids;
    }
    if(this.postForm.value.name != "" && this.postForm.value.email != "" && this.postForm.value.password != ""){      
      this.usersService.createUser(this.postForm.value)
        .subscribe((response: any) => {
        
            alert("Usuário criado com sucesso!");
            this.router.navigateByUrl('/admin/users');
        },
        error=> {
          alert("Ocorreu um erro ao criar o usuário!");
      });
    }else{
      let field = "";
      if(this.postForm.value.name == ""){
        field = "nome"
      }else if(this.postForm.value.email == ""){
        field = "email"
      }else if(this.postForm.value.password == ""){
        field = "senha"
      }

      alert("O campo "+field+" precisa ser preenchido!")
    }
  }
  
  verifySelect(){
    this.selectedCompanies.forEach(el => {
      if(el.name == "Selecionar todos"){
        let whitoutSa = [];
        this.allProviders.forEach((dt)=>{
          if(dt.id != 1){
            whitoutSa.push(dt)
          }
        })
        this.selectedCompanies = whitoutSa
      }
    });
  }


  ngOnInit() {
    this.spinner.show();

    this.postForm  =  this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      origins: ['', Validators.required],
      channels: ['', Validators.required]
    });
    
    this.providersService.getAllProvidersCreateEdit()
    .subscribe((response: any) => {
      this.spinner.hide();
      this.allProviders.push({ id: 1, name: "Selecionar todos"});
      response.data.forEach((dt) => {
        this.allProviders.push({ id: dt.origin, name: dt.name });
      }); 
         this.showForm = true;   
      
      let oc = document.getElementById("origins-container");
      let cc = document.getElementById("channels-container");
      let udType = localStorage.getItem('USER_EDIT_TYPE')

      if(oc != undefined){

        if (udType == "provedor" ){
          oc.style.display = "block"
        }else{
          oc.style.display = "none"
        }
      }  
      if (cc != undefined){
        if (udType == "programadora" ){
          cc.style.display = "block"
        }else{
          cc.style.display = "none"
        }
      }   


      this.providersService.getChannels()
      .subscribe((response: any) => {
        this.channelsList = response
      });



    },
    error=> {
      this.spinner.hide();
      alert("Sua sessão expirou, faça login novamente!");
      this.router.navigateByUrl('/login');
    });
  }
}
interface UserData {
  name ? : string;
  email ? : string;
  password ? : string;
  type ? : string;
  origins ? : string;
}
